import {
  UserDataReducerState,
} from "./user-reducer-state";
import { USERDATA } from "../types";
import { DVMDetails } from "../../app/model/dvm-details";

const userDataInitialState: UserDataReducerState = {
  userData: [],
};

export const UserData: any = (
  isLogin: UserDataReducerState = userDataInitialState,
  action: any
) => {
  switch (action.type) {
    case USERDATA:
      return action.data;
    default:
      return isLogin;
  }
};
