import React, { useEffect, useState } from 'react'
import CheckMark from './../../assets/images/check-mark.jpg';
import { NavLink, useParams } from 'react-router-dom';
import { GET_USER_SHIPPING_ADDRESS } from '../../app/services/user-address-shipping.service';
import { ShippingAddressResponseData } from '../../app/model/shipping-address';
import toast from 'react-hot-toast';

export const ThankYouAndInsuranceOffers = () => {
    const params: any = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [userAddressData, setUserAddressData] = useState<any>()

    useEffect(() => {
        window.scrollTo(0, 0);
        getUserShippingAddress()
    }, []);

    const getUserShippingAddress = () => {
        setLoading(true);
        GET_USER_SHIPPING_ADDRESS({ uuid: params?.address_uuid ? params?.address_uuid : '' }).then((res: ShippingAddressResponseData) => {
            if (res?.status === "fail") {
                toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.error?.data ? res?.error?.data : res?.message, { position: "top-right" })
                setLoading(false);
            } else {
                if (res) {
                    if (res?.data?.data.length > 0) {
                        const response = res?.data?.data[0];
                        setUserAddressData(response);
                    }
                    setLoading(false);
                }
            }
        });
    }

    return (
        <div className="primary-bg mt-xl-2">
            <div className='container'>
                <div className='row mx-0'>
                    <div className='col-md-12 py-2 primary-bg mx-md-auto'>
                        <div className='row py-4 p-md-3'>
                            <div className='col-11 col-md-11 bg-white py-3 p-md-3 mx-auto rounded-4 border-0 text-center'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='mx-auto'>
                                            <img src={CheckMark} />
                                        </div>
                                        <h4 className='fw-bold fs-32 ls-1 text-center font-Oswald fw-300 my-3'>Thank you for using Simple Tags!</h4>
                                        <div className='font-proxima-nova fs-16 my-3 fw-700'>Your vehicle registration has been renewed with the DMV and fees have been paid.</div>
                                        <div className='fs-13 my-3 px-4 fw-400'>You will be receiving a digital copy of your renewal registration shortly. Your official registration document and sticker <br />will be mailed as soon as possible. Thank you for your patience and wishing you and your the best of health.</div>
                                        <div className='my-2 mt-4'>
                                            <NavLink to={`/login?uuid=${params?.user_uuid}`}><button className='px-3 fs-18 border-0 primary-bg primary-button order_status_button font-Oswald fw-500'>CHECK ORDER STATUS</button></NavLink>
                                            {/* <NavLink to={`/order-status/${params?.user_uuid}/${params?.cart_uuid}`}><button className='px-3 fs-18 border-0 primary-bg primary-button order_status_button font-Oswald fw-500'>CHECK ORDER STATUS</button></NavLink> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-11 col-md-11 insurance-bg my-4 py-3 p-md-3 mx-auto rounded-4 border-0 text-center'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <h4 className='fw-bold fs-32 ls-1 text-center font-Oswald fw-300 mt-lg-5 my-3'>Auto Insurance Offers</h4>
                                        {/* <div className='font-proxima-nova fs-5 my-3'>Your vehicle registration has been renewed with DMV and fees have been paid.</div> */}
                                        <div className='fs-16 my-3 px-4 fw-500 px-md-5 mt-xl-4'>You may qualify for special auto insurance rates.<br /> Check these updated rates, as you may be overpaying for car insurance in {userAddressData?.zip_code} </div>
                                        <div className='my-2 mt-4'>
                                            <NavLink to={`/order-status/${params?.user_uuid}/${params?.cart_uuid}`}><button className='px-3 fs-18 rounded border-0 primary-bg primary-button font-Oswald fw-500 view_quotes_button'>VIEW QUOTES</button></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
