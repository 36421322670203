import React from "react";

interface Props {
  LoadingReducer?: boolean;
}

export const Loader: React.FC<Props> = (props: Props) => {
  return (
    <>
      {props?.LoadingReducer && (
        <div className="text-center p-5 custom_spinner">
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      )}
    </>
  );
};

