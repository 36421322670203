export const formatPhoneNumber = (phone: string) => {
    // Check if the phone number has exactly 10 digits
    if (phone.length === 10) {
        return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
    // Return the original string if it doesn't have 10 digits
    return phone;
};

export const getOnlyNumbers = (phone: string) => {
    return phone.replace(/\D/g, '');
};