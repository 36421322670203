import React, { useEffect } from 'react'

export const FrequentlyAskedQuestions = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='container mx-auto'>
            <h2 className='text-center my-4 font-Oswald font-bebas fw-normal'>FREQUENTLY ASKED QUESTIONS</h2>
            <div className='row px-md-5'>
                <div className="col-lg-6 p-3 p-md-3 p-lg-5 d-flex flex-column justify-content-center">
                    <div>
                        <div className='fw-bold '>Q. What transaction types are eligible?</div>
                        <div className='my-3'>A: Registration renewals, sticker replacements, registration card replacements, duplicate titles, and title transfers.</div>
                    </div>
                    <div>
                        <div className='fw-bold'>Q. I am unable to renew my vehicle registration on the DMV's website, can Simple Tags help me?</div>
                        <div className='my-3'>A: Of course. SimpleTags.com provides services to help with online vehicle registrations that are complicated, or need proof
                            of insurance, have parking/toll violations, suspended registration, expired registration, etc.</div>
                    </div>
                    <div>
                        <div className='fw-bold'>Q. I lost my registration renewal notice, can I still use your services?</div>
                        <div className='my-md-3'>A: Yes, you can. Simply provide your vehicle license plate and partial VIN information to start your order.</div>
                    </div>
                </div>
                <div className="col-lg-6 p-3 p-md-3 p-lg-5">
                    <div>
                        <div className='fw-bold'>Q. My vehicle needs a smog test. Can I use your services?</div>
                        <div className='my-3'>A: Registration renewals, sticker replacements, registration card replacements, duplicate titles, and title transfers.</div>
                    </div>
                    <div>
                        <div className='fw-bold'>Q. What vehicle types can I renew or transfer with SimpleTags.com?</div>
                        <div className='my-3'>A: Registration renewals, sticker replacements, registration card replacements. duplicate titles, and title transfers.</div>
                    </div>
                    <div>
                        <div className='fw-bold'>Q. Do I need proof of auto insurance to renew my registration?</div>
                        <div className='my-3'>A: Not necessarily. If your insurance information is not on file with the CA DMV. you may be asked to provide proof of automobile liability insurance to complete your registration renewal.</div>
                    </div>
                    <div>
                        <div className='fw-bold'>Q. I have questions and need more information. Can I contact you?</div>
                        <div className='my-3'>A: Yes please contact us.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
