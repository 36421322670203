import React from 'react'
import { SideMenu } from '../../components/side-menu/side-menu'
import { DashboardRoutes } from '../../routes/dashboard-routes'
import { useParams } from 'react-router-dom'

export const Dashboard = () => {
    const params = useParams();
    return (
        <div className='container-fluid mobile_bg_primary'>
            <div className='border-top border-xl-bottom pb-5 pb-sm-0'>
                <div className='mobile_dashboard'>
                    <div className='row'>
                        <div className='col-4 col-md-2 border-end'>
                            <SideMenu />
                        </div>
                        <div className='col-8 col-md-10 '>
                            <DashboardRoutes />
                        </div>
                        
                    </div>
                    <div className='row border-top'>
                        <div className='col-md-12 fs-2 text-center fw-bold my-4'>Need Assistance? <a className='text-decoration-none fw-bold cursor-pointer'>Click here.</a></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
