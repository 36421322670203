import React, { useEffect, useState } from 'react'
import Car from './../../../assets/images/SimpleTags_icons_car.png';
import { GET_USER_ORDER } from '../../../app/services/user-orders.service';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../components/loader';
import moment from 'moment';
import { OrdersData, OrdersResponse } from '../../../app/model/orders';
import toast from 'react-hot-toast';
import { USER_VEHICLE_INFO } from '../../../app/services/vehicle-details.service';
import { VehicleResponse } from '../../../app/model/vehicle-details';
import { DVMDetails } from '../../../app/model/dvm-details';

export const MyGarage = () => {
    const [sortByButton, setSortByButton] = useState(true);
    const params = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedSorting, setSelectedSorting] = useState<string>('PLATE NUMBER');

    const [userOrders, setUserOrders] = useState<any[]>([]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        getUserVehicleInfo();
    }, [])

    const getUserVehicleInfo = () => {
        setLoading(true);
        USER_VEHICLE_INFO({ user_uuid: params?.user_uuid ? params?.user_uuid : '' }).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.error?.data ? res?.error?.data : res?.message, { position: "top-right" })
                setLoading(false);
            } else {
                if (res) {
                    setUserOrders([...res?.data?.data]);
                    setLoading(false);
                }
            }
        });
    }

    const [sortOrder, setSortOrder] = useState('asc');
    const handleSortBy = (sort: string, field: string) => {
        // setSortByButton(true)
        setSelectedSorting(sort);
        // const sortedData = [...userOrders].sort((a, b) => {
        //     if (a?.vehicle_license_plate && b?.vehicle_license_plate) {
        //         if (sortOrder === 'asc') {
        //             return a.vehicle_license_plate.localeCompare(b.vehicle_license_plate);
        //         } else {
        //             return b.vehicle_license_plate.localeCompare(a.vehicle_license_plate);
        //         }
        //     }
        //     return 0;
        // });
        const sortedData = [...userOrders].sort((a, b) => {
            const valueA = a[field]?.toString().toLowerCase() || '';
            const valueB = b[field]?.toString().toLowerCase() || '';

            if (sortOrder === 'asc') {
                return valueA.localeCompare(valueB);
            } else {
                return valueB.localeCompare(valueA);
            }
        });

        setUserOrders(sortedData);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    return (
        <div className='container-fluid login-container'>
            <div className="row my-3">
                <Loader LoadingReducer={loading} />
                <div className="col-md-15 px-lg-5 mx-auto">
                    <h4 className='fw-bold font-Oswald my-2 fs-40 ls-1 '>MY GARAGE</h4>

                    <div className="dropdown mb-2">
                        <button
                            className="px-3 py-1 btn btn-outline-secondary dropdown-toggle"
                            type="button"
                            id="sortDropdown"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            Sort By <span className='fw-600'>{selectedSorting}</span>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="sortDropdown">
                            <li><span className={`dropdown-item ${selectedSorting === 'PLATE NUMBER' && 'primary-bg'}`} onClick={() => handleSortBy('PLATE NUMBER', 'vehicle_license_plate')}>PLATE NUMBER</span></li>
                            <li><span className={`dropdown-item ${selectedSorting === 'VIN NUMBER' && 'primary-bg'}`} onClick={() => handleSortBy('VIN NUMBER', 'vehicle_vin_number')}>VIN NUMBER</span></li>
                            <li><span className={`dropdown-item ${selectedSorting === 'RENEWAL DATE' && 'primary-bg'}`} onClick={() => handleSortBy('RENEWAL DATE', 'vehicle_registration_expiry_date')}>RENEWAL DATE</span></li>
                        </ul>
                    </div>

                    {/* {!sortByButton && (<div className='mb-3'>
                        <button className='px-3 py-1 rounded border-0 sort-button text-white' onClick={handleSortBy}>SORT BY</button>
                    </div>
                    )}
                    {sortByButton && (<div className='my-1 mb-2'>
                        <button className='px-3 py-1 rounded border border-dark bg-transparent text-dark fs-16 fw-400' onClick={handleSortBy}>SORT BY PLATE NUMBER</button>
                    </div>)} */}
                    {sortByButton && (
                        <div className='row px-md-4 mx-md-3'>
                            {userOrders?.map((vehicle: DVMDetails, index: number) => (
                                <div className='col-md-3 my-2' key={index}>
                                    <div className='card insurance-bg mx-auto'>
                                        <div className='mx-auto d-flex justify-content-center'>
                                            <img src={Car} className='w-25' />
                                        </div>
                                        <div className='fw-bold text-center number-plate-color fs-20'>{vehicle?.vehicle_license_plate}</div>
                                        <div className='text-center number-plate-color'>{vehicle?.vehicle_make}</div>
                                        <div className='text-center number-plate-color fs-12'>VIN : {vehicle?.vehicle_vin_number}</div>
                                        {/* <div className='text-center number-plate-color'>Order Status : {vehicle?.order_status === 0 ? 'PENDING' : 'COMPLETED'}</div>
                                        <div className='text-center number-plate-color'>Shipping Status : {vehicle?.shipping_status}</div> */}
                                        <div className='my-3 text-center'>
                                            <button className='renewed-bg border-0 px-3 py-2'>
                                                <>
                                                    <div className='text-white'>RENEWED UNTIL</div>
                                                    <div className='text-white fw-bold'>{moment(vehicle?.vehicle_registration_expiry_date).format('MM-DD')}</div>
                                                </>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {userOrders?.length == 0 && (<div className='text-center'>You don't have added vehicles</div>)}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
