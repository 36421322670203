import { CommonResponse } from "../model/common";
import { LoginUserResponse, SendOTPPayload } from "../model/login";
import API from "../utils/axios";
let common = "/v1/auth";

export const SEND_OTP = (data: SendOTPPayload): Promise<CommonResponse> => {
  return API.post(`${common}/sendOTP`, data);
};

export const USER_AUTHENTICATIONS = (data: SendOTPPayload): Promise<CommonResponse> => {
  return API.post(`v1/api/user/authentications`, data);
};

export const LOGIN_USER = (data: SendOTPPayload): Promise<LoginUserResponse> => {
  return API.post(`${common}/login/user`, data);
};
