import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { ShippingAddressVehicleDetails } from '../../components/common-components/shipping-address/shipping-address-vehicle-details'
import CreditCardImages from './../../assets/images/credit-card-images.jpg';

export const PaymentInformation = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className='col-md-12'>
            <div className='row mx-0'>
                <div className='col-md-12 py-4 primary-bg mx-md-auto'>
                    <div className='row py-4 py-md-5 p-md-3'>
                        <div className='col-11 col-md-10 bg-white py-3 p-md-3 p-lg-5 mx-auto rounded-4 border-0 text-center'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <h4 className='fw-bold fs-4 text-center text-md-start font-Oswald fw-300'>Payment Information</h4>
                                    <div className='text-start'>Your vehicle registration renewal fee calculation is valid today only. Pay now  and avoid DMV fines and penalties.</div>
                                    <div className='row my-3 text-start'>
                                        <div className='col-md-6 my-1 '>
                                            <label className='form-label fw-bold'>Cardholder First Name</label>
                                            <input type="text" name="first_name" className='form-control' />
                                        </div>
                                        <div className='col-md-6 my-1'>
                                            <label className='form-label fw-bold'>Cardholder Last Name</label>
                                            <input type="text" name="last_name" className='form-control' />
                                        </div>
                                        <div className='col-md-7 my-1'>
                                            <label className='form-label fw-bold'>Credit Card Number</label>
                                            <input type="text" name="credit_card_number" className='form-control' />
                                        </div>
                                        <div className='col-md-5 my-3 my-md-0 ms-auto ms-md-0 d-flex justify-content-end '>
                                            <img src={CreditCardImages} className='w-75 w-lg-100 mt-md-4 pt-md-2 ms-auto' />
                                        </div>
                                        <div className='col-md-12 my-1'></div>
                                        <div className="form-check ms-3">
                                            <input className="form-check-input fw-bold cursor-pointer" type="checkbox" defaultChecked name="flexRadioDefault" id="flexRadioDefault3" />
                                            <label className="form-check-label ms-2 my-1 label-position" htmlFor="flexRadioDefault3">Renewal Reminders - Receive paperless DMV registration reminders with a smart link to make your registration checkout fast, easy, and on-time.
                                                Avoid late fees, registration citations, and expired tag tickets with reminders.</label>
                                        </div>
                                        <div className="form-check ms-3">
                                            <input className="form-check-input fw-bold cursor-pointer" type="checkbox" defaultChecked name="flexRadioDefault" id="flexRadioDefault3" />
                                            <label className="form-check-label ms-2 my-1 label-position" htmlFor="flexRadioDefault3">I agree to the Terms of Use and Electronic Signature Consent. Registration payment transmitted to the DMV cannot be cancelled or reversed.</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 order-2 order-lg-1 '>
                                    <ShippingAddressVehicleDetails paymentInformation={true} />
                                </div>
                                <div className='col-md-12 d-none d-lg-block col-lg-6 order-1 order-lg-2 mt-4 mb-lg-0 d-xl-flex justify-content-between px-xl-5'>
                                    <div className='mb-4 mb-md-0'>
                                        <button className='px-3 fs-5 rounded-1 border-0 primary-bg primary-button_custom_btn fw-500'>BACK</button>
                                    </div>
                                    <div>
                                        <NavLink to="/thank-you"><button className='px-3 fs-5 rounded-1 border-0 primary-bg primary-button fw-500'>PROCESS ORDER</button></NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
