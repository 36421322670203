import React, { useEffect } from 'react';
import { HomePageComponents } from "../../components/home-page-component/home-page"

export const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='container-fluid px-0'>
      <div className=''>
        <HomePageComponents />
      </div>
    </div>
  );
}
