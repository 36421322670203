import React, { useEffect } from 'react'
import { FrequentlyAskedQuestions } from '../../components/faq/frequently-asked-questions'
import { NavLink } from 'react-router-dom';
import { RegistrationSteps } from '../../app/model/registration-steps';

export const DmvSearchFinalized = () => {
  const registrationSteps: RegistrationSteps[] = [
    {
      title: "INSTANT",
      caption: "Registration Card",
      description: "Download a digital copy of your new CA DMV registration card instantly for free. Print out your e-Card or display it on your mobile device anytime."
    },
    {
      title: "EXPEDITIED",
      caption: "DMV Processing",
      description: "Get DMV tags as fast as next day with API driven technology. Our proprietary platform is build to expedite the processing of your vehicle's registration."
    },
    {
      title: "ANYTIME",
      caption: "Fee Payment",
      description: "No smog or insurance? Not a problem. Avoid late fees and long DMV lines. Our system will accept you payment and post your renewal fees anytime."
    },
    {
      title: "ANYWHERE",
      caption: "Shipping",
      description: "Recently moved? Just let us know your new address. We ensure you get your sticker and registration card, guaranteed. We ship anywhere in the U.S"
    },
    {
      title: "PERSONAL",
      caption: "Assistance",
      description: "Get back on the road in no time. A dedicated agent will help you resolve your registration suspension, insurance, or ticket issues, expeditously online."
    },
    {
      title: "PERSONAL - 2",
      caption: "Assistance",
      description: "Get back on the road in no time. A dedicated agent will help you resolve your registration suspension, insurance, or ticket issues, expeditously online."
    },
  ]

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='mt-xl-5'>
      <div className='container-fluid'>
        <div className="row">
          <div className='py-4 primary-bg mx-auto'>
            <div className='container'>
              <div className='row py-4 p-md-3 mx-3'>
                <div className='col-12 bg-white py-3 p-md-3 mx-auto rounded-4 border-0 text-center'>
                  <h3 className='font-Oswald fs-36 ls-1 fw-bold'>DMV Search Finalized</h3>
                  <div className='fs-14 fw-500 col-md-9 mx-auto font-proxima-nova my-4 px-2 px-md-5'>The DMV confirms that your registration does not expire until 22-05-2025 and is not currently eligible for
                    renewal. Renewal can only be initiated with in 75 days of the expiration date. Please see below for next steps:
                  </div>
                  <div className='col-lg-12 col-xl-6 mx-auto px-2'>
                    <div className="form-check my-3 cursor-pointer">
                      <input className="form-check-input fw-bold cursor-pointer" type="checkbox" defaultChecked name="flexRadioDefault" id="flexRadioDefault1" />
                      <label className="form-check-label ms-3 my-1 fs-14 fw-400 cursor-pointer text-start d-flex justify-content-start" htmlFor="flexRadioDefault1">Send me a renewal reminder when my registration is eligible for renewal.</label>
                    </div>
                    <div className="form-check my-3 cursor-pointer">
                      <input className="form-check-input fw-bold cursor-pointer" type="checkbox" defaultChecked name="flexRadioDefault" id="flexRadioDefault2" />
                      <label className="form-check-label ms-3 my-1 fs-14 fw-400 cursor-pointer text-start d-flex justify-content-start" htmlFor="flexRadioDefault2">I need to replace my lost registration card/sticker.</label>
                    </div>
                    <div className="form-check my-3 cursor-pointer">
                      <input className="form-check-input fw-bold cursor-pointer" type="checkbox" defaultChecked name="flexRadioDefault" id="flexRadioDefault3" />
                      <label className="form-check-label ms-3 my-1 fs-14 fw-400 cursor-pointer text-start d-flex justify-content-start" htmlFor="flexRadioDefault3">I would like to renew the registration for another vehicle.</label>
                    </div>
                  </div>
                  <div className='my-4'>
                    <NavLink to="/thanks-you" className="text-decoration-none"><button className='px-3 fs-5 rounded-1 border-0 primary-bg primary-button fw-500'>SUBMIT</button></NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12 text-center py-5'>
            <div className='font-Oswald fw-bold fs-36 ls-1 fw-bold'>CA Department of Motor Vehicles Services Delegated to Simple Tags</div>
          </div>
          <div className='container'>
            <div className='row mx-auto'>
              {registrationSteps?.map((data: RegistrationSteps, index: number) => (
                <div className={`col-12 col-md-6 col-lg-2 px-0 `} key={index}>
                  <div className='row bg-dark text-center py-4 px-3'>
                    <div className='primary-color fw-bold fs-25 my-3 font-Oswald'>{data?.title}</div>
                    <div className='text-white font-proxima-nova fs-20'>{data?.caption}</div>
                  </div>
                  <div className='row bg-white py-4 my-3 px-3 px-md-5 px-xl-3'>
                    <div className='fs-16 fw-500'>{data?.description}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='faq-bg border border-dark'>
            <div className='col-md-12'>
              <FrequentlyAskedQuestions />
            </div>
          </div>
          <div className='row py-5'>
            <div className='col-12 col-md-4 ms-auto text-center d-flex flex-column justify-content-start my-2 my-md-0'>
              <div>
                <i className="bi bi-star-fill start-color"></i>
                <i className="bi bi-star-fill start-color mx-1"></i>
                <i className="bi bi-star-fill start-color"></i>
                <i className="bi bi-star-fill start-color mx-1"></i>
                <i className="bi bi-star-fill start-color"></i>
              </div>
              <div className='font-bebas me-3 pe-0 pe-md-0 me-md-0 fs-5'>CHRIS RHODE</div>
              <div className='font-Oswald pe-2 pe-md-0 me-md-0 fw-normal'>Los Angles,CA</div>
            </div>
            <div className='col-12 col-md-8 text-start fst-italic ms-auto'>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
