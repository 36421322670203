import React from 'react';
import './App.css';
import './assets/styles/css/styles.css'
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import { RootNavigationPage } from './routes';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <div>
      <Toaster />
      <RootNavigationPage />
    </div>
  );
}

export default App;
