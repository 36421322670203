import React, { useEffect } from 'react'
import { DataTable } from '../../components/common-components/data-table'
import { VehicleBillSummary } from '../../components/common-components/add-another-vehicle/vehicle-bill-summary'
import { NavLink } from 'react-router-dom'
import { VehicleInfoBillSummary } from '../../components/common-components/add-another-vehicle/vehicle-info-bill-summary'

export const AddMultipleVehicles = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='col-md-12'>
            <div className='row mx-0'>
                <div className='col-md-12 py-4 primary-bg mx-md-auto'>
                    <div className='row py-4 p-md-3'>
                        <div className='col-11 col-md-10 bg-white py-3 p-md-3 mx-auto rounded-4 border-0 text-center'>
                            <div className='row'>
                                <div className='col-lg-6 px-3 px-md-5'>
                                    <div>
                                        <h4 className='font-Oswald text-start'>Add Another Vehicle To This Order</h4>
                                        <div className='fw-normal text-start my-3'>
                                            You can add multiple vehicles to same order. We save each vehicle on this page until you are ready to check out.
                                        </div>
                                        <div className='fw-normal text-start'>
                                            If you only have one vehicle for this order, simply click check out to continue.
                                        </div>
                                    </div>
                                    <div className='my-5 py-lg-5 d-flex flex-column justify-content-center align-items-center'>
                                        <NavLink className="text-decoration-none" to="/vehicle-registration"><button className='px-4 fs-3 rounded border-0 primary-bg primary-button font-bebas'>ADD ANOTHER VEHICLE</button></NavLink>
                                    </div>
                                </div>
                                <div className='col-lg-6 d-none d-sm-block px-3 px-md-5'>
                                    <DataTable multipleOrders={true} />
                                    <div className='border border-dark my-3'>
                                        <div className='text-end fw-bold me-5 py-2'><span className='fs-5'>Total:</span><span className='fs-5'> $ 5,000.00</span></div>
                                    </div>
                                    <div className='border border-dark my-3'>
                                        <div className='text-center fw-md-bold py-2 fs-4 font-Oswald'>7ABC123 - 1 Year Renewal</div>
                                    </div>
                                    <VehicleBillSummary />
                                    <VehicleBillSummary />
                                    <div className='row  border-bottom border-dark my-2'>
                                        <div className='col-6 text-end fw-bold'>Contact info</div>
                                        <div className='col-6 text-start'>
                                            <div className='text-start'>simpletags@info.com</div>
                                            <div className='text-start'>+1 (310) 555-1234</div>
                                        </div>
                                        <div className='col-12 text-end my-2 my-md-3 mt-4 mt-md-2 pe-xl-5 '>
                                            <button className='px-3 fs-5 rounded-1 border-0 primary-bg primary-button fw-500'>EDIT CONTACT INFO</button>
                                        </div>
                                    </div>
                                    <div className='col-md-12 my-3 text-end pe-xl-5 '>
                                        <NavLink to="/payment-information"><button className='px-4 fs-4 me-xl-5 rounded border-0 checkout-button fw-500'>CHECK OUT</button></NavLink>
                                    </div>
                                </div>
                                <div className='col-lg-6 d-block d-sm-none'>
                                    <VehicleInfoBillSummary />
                                    <VehicleInfoBillSummary />
                                    <div className='row  border-bottom border-dark my-2'>
                                        <div className='col-6 text-end fw-bold'>Contact info</div>
                                        <div className='col-6 text-start'>
                                            <div className='text-start'>simpletags@info.com</div>
                                            <div className='text-start'>+1 (310) 555-1234</div>
                                        </div>
                                        <div className='col-12 text-end my-2 my-md-3 mt-4 mt-md-2'>
                                            <button className='px-3 fs-5 rounded-1 border-0 primary-bg primary-button fw-500'>EDIT CONTACT INFO</button>
                                        </div>
                                    </div>
                                    <div className='col-md-12 my-3 text-end'>
                                        <NavLink to="/payment-information"><button className='px-4 fs-4 rounded border-0 primary-bg checkout-button fw-500'>CHECK OUT</button></NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
