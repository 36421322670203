import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LOGIN_USER, SEND_OTP, USER_AUTHENTICATIONS } from '../../app/services/login.service';
import toast from "react-hot-toast";
import { Loader } from '../../components/loader';
import ProfileImage from './../../assets/images/profile.avif';
import { LoginUserResponse, SendOTPPayload } from '../../app/model/login';
import { CommonResponse, DynamicObject } from '../../app/model/common';
import { GET_USER } from '../../app/services/user.service';
import { UserResponse } from '../../app/model/user-info';

interface Props {
    isLoginPage?: any;
}

export const LogIn: React.FC = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const navigation = useNavigate();
    const [emailData, setEmailData] = useState<SendOTPPayload>({
        email: ""
    })
    const [otpData, setOTPData] = useState<SendOTPPayload>({
        email: "",
        otp: ""
    })
    const [formErrors, setFormErrors] = useState<DynamicObject>({});
    const [showOtp, setShowOtp] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const uuid: string | null = searchParams.get('uuid');
    useEffect(() => {
        const data = sessionStorage?.getItem('login-status')//props?.isLoginPage
        if (data) {
            setShowOtp(false)
        }
        if (uuid) {
            getUserInfo();
        }
    }, [])

    const getUserInfo = () => {
        setLoading(true);
        GET_USER({ uuid: uuid ? uuid : '' }).then((res: UserResponse) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    setLoading(false);
                    emailData.email = res?.data?.data[0]?.email || '';
                    onGetOTP();
                }
            }
        });
    }


    const onGetOTP = () => {
        const isValid = validateForm();
        setLoading(true);
        if (isValid) {
            const payload = {
                user_uuid: '',
                otp_type: "email",
                mobile_number: "",
                email: emailData?.email,
                param_code: "EMAIL_LOGIN_REGISTER"
            }
            USER_AUTHENTICATIONS(payload).then((res: any) => {
                if (res?.status === "fail") {
                    setLoading(false);
                    toast.error(res?.message, { position: "top-right" });
                } else {
                    if (res) {
                        const data = otpData;
                        data.otp = '';
                        setOTPData({ ...data });
                        setLoading(false);
                        setShowOtp(true);
                        toast.success(res?.message, { position: "top-right" });
                    }
                }
            });
        } else {
            setShowOtp(false)
        }
    }

    const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setEmailData((prevData: SendOTPPayload) => ({
            ...prevData,
            [name]: value,
        }));

        setFormErrors((prevErrors: DynamicObject) => ({
            ...prevErrors,
            [name]: validateSpecificField(name, value),
        }));
    }

    const handleChangeOtp = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setOTPData((prevData: SendOTPPayload) => ({
            ...prevData,
            [name]: value,
        }));

        setFormErrors((prevErrors: DynamicObject) => ({
            ...prevErrors,
            [name]: validateSpecificField(name, value),
        }));
    }

    const validateForm = () => {
        const errors: DynamicObject = {};
        if (!emailData?.email) {
            errors.email = "Please enter email address";
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const validateSpecificField = (name: string, value: string) => {
        if (name === "email") {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!value) {
                return "Please enter email address"
            } else if (!emailRegex.test(value)) {
                return "Please enter a valid email address";
            }
        }

        if (name === "otp") {
            const otpRegEX = /^\d{4}$/
            if (!value) {
                return "Please enter otp"
            } else if (!otpRegEX.test(value)) {
                return "Please enter a valid otp"
            }
        }
        return "";
    }

    const validateOTPForm = () => {
        const errors: DynamicObject = {};
        if (!otpData?.otp) {
            errors.otp = "Please enter otp"
        }
        setFormErrors(errors)
        return Object.keys(errors).length === 0;
    }

    const onSubmit = () => {
        setLoading(true);
        const isValid = validateOTPForm();
        if (isValid) {
            const payload = {
                type: "email",
                email: emailData?.email,
                mobile_country_code: "",
                mobile_number: "",
                password: "",
                otp: otpData?.otp
            }
            LOGIN_USER(payload).then((res: LoginUserResponse) => {
                if (res?.status === "fail") {
                    setLoading(false);
                    // toast.error("Invalid email!", { position: "top-right" });
                    toast.error(res?.error?.data, { position: "top-right" });
                    const errors: DynamicObject = {};
                    errors.otp = res?.error?.data;
                    setShowOtp(false);
                    setFormErrors({ ...errors });
                } else {
                    if (res) {
                        setLoading(false);
                        navigation(`/dashboard/my-orders/${res?.data?.user_uuid}`);
                        if (props?.isLoginPage) {
                            props?.isLoginPage(res?.data)
                        }
                        sessionStorage.setItem('user_id', res?.data?.user_uuid);
                        sessionStorage.setItem('user_name', res?.data?.user_name ? res?.data?.user_name : "");
                        sessionStorage.setItem('user_email', res?.data?.user_email ? res?.data?.user_email : "");
                        getUserDetails(res?.data?.user_uuid)
                        toast.success(res?.message, { position: "top-right" });
                    }
                }
            });
        } else {
            setLoading(false);
        }
    }

    const getUserDetails = (userUuid: string) => {
        GET_USER({ uuid: userUuid }).then((res: UserResponse) => {
            if (res?.status === "fail") {
                toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.error?.data ? res?.error?.data : res?.message, { position: "top-right" })
            } else {
                if (res) {
                    sessionStorage?.setItem('profile-image', res?.data?.data[0]?.profile_image ? res?.data?.data[0]?.profile_image : ProfileImage)
                }
            }
        });
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>) => {
        if (event.key === 'Enter') {
            onGetOTP();
        }
    };

    const handleKeyDownOTP = (event: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>) => {
        if (event.key === 'Enter') {
            onSubmit();
        }
    };
    return (
        <div className='container-fluid login-container '>
            <div className="row d-flex flex-column justify-content-center">
                <Loader LoadingReducer={loading} />
                <div className="col-md-12 mt-5">
                    <div className='text-center pt-3'>
                        <h4 className='primary-color font-Oswald fs-40 fw-400 ls-1 mb-0'>LOG INTO YOUR SIMPLE TAGS ACCOUNT</h4>
                        {!showOtp && (<>
                            <div className='font-proxima-nova mb-2 fw-500 fs-18'>Log in using your email address to view your orders.</div>
                            <div className='col-10 col-lg-5 col-xl-4 px-md-5 px-lg-4 mx-auto my-3'>
                                <input type="text" className="form-control" autoComplete="email" name="email" placeholder='' defaultValue={emailData?.email} onChange={handleChangeEmail} onKeyDown={handleKeyDown} />
                                {formErrors.email && (<div className="text-danger text-start w-100"> {formErrors.email} </div>)}
                            </div>
                            <div className='my-1'>
                                <button className='secondary-button border-0 rounded px-3' onClick={onGetOTP}>GET OTP</button>
                            </div>
                        </>)}
                        {showOtp && (
                            <div className='col-10 col-lg-5 col-xl-4 px-md-5 px-lg-4 mx-auto my-3'>
                                <label className='font-proxima-nova mt-3 fw-normal d-flex justify-content-start'>Please enter OTP</label>
                                <div className=''>
                                    <input type="text" maxLength={6} className="form-control" name="otp" autoComplete="one-time-code" placeholder='Please enter otp' value={otpData?.otp} onChange={handleChangeOtp} onKeyDown={handleKeyDownOTP} />
                                    {formErrors.otp && (<div className="text-danger text-start w-100"> {formErrors.otp} </div>)}
                                </div>
                                <div className='my-2 mt-3'>
                                    <button className='secondary-button border-0 rounded px-3' onClick={onSubmit}>SUBMIT</button>
                                </div>
                            </div>
                        )}
                        {/* <div className='d-block d-md-none'>Have a business account? <a className='text-decoration-none fw-bold'>Click here</a> to log in.</div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
