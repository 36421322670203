import React from 'react'
import REVISED1Icon from "../../assets/images/numbered titles-REVISED_1.png";
import REVISED2Icon from "../../assets/images/numbered titles-REVISED_2.png";
import REVISED3Icon from "../../assets/images/numbered titles-REVISED_3.png";
import REVISED4Icon from "../../assets/images/numbered titles-REVISED_4.png";
import { CommonStaticData } from '../../app/model/common';

export const NoMoreWaitingSection: React.FC = () => {
    const registrationSteps: CommonStaticData[] = [
        {
            image: REVISED1Icon,
            description: "Provide your vehicle information, license plate number along with the last 3 digits of the VIN and ZIP code where vehicle is registered."
        },
        {
            image: REVISED2Icon,
            description: "Fees are calculated automatically, and you can make payment online for any selected service."
        },
        {
            image: REVISED3Icon,
            description: "SimpleTags provides an online alternative to the DMV, saving you time and headaches."
        },
        {
            image: REVISED4Icon,
            description: "Select from several online services we provide as a DWV Delegated Provider."
        },
    ]
    return (
        <div className='px-md-4 pb-5'>
            <div className="container mt-5 px-5">
                <h5 className="fw-bold text-center font-Oswald fs-36 ls-2 ws-1 mb-0">
                    NO MORE WAITING IN LINE FOR VEHICLE REGISTRATIONS ,
                </h5>
                <h5 className="fw-bold text-center font-Oswald fs-36 ls-2 ws-1">RENEWALS, TRANSFERS, AND MORE!</h5>
                <div className="row mb-2">
                    <div className="col-6">
                        <div className="middle-border"></div>
                    </div>
                </div>
                <div className="container">
                    <div className="row px-2 px-sm-5 px-md-0">
                        {registrationSteps?.map((step: CommonStaticData, index: number) => (
                            <div className="col-xl-3 col-sm-6 col-12 my-3 my-md-0" key={index}>
                                <img src={step?.image} className="" style={{maxWidth:"inherit"}}/>
                                <div className="my-3 text-start px-sm-3 custom-gray">{step?.description}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}