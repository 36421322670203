import React from 'react'
import { AllThingDMV } from '../../components/home-page-component/all-things-dmv'
import { NoMoreWaitingSection } from '../../components/home-page-component/no-more-waiting'
import { ServiceDmvRecordsCheck } from '../../components/dmv-records-check/service-dmv-records-check'
import { VehicleServices } from '../../components/home-page-component/vehicle-services'

export const DmvRecordsCheck = () => {
    return (
        <div className='container-fluid'>
            <div className="row">
                <AllThingDMV />
                <ServiceDmvRecordsCheck />
                <VehicleServices />
                <NoMoreWaitingSection />
            </div>
        </div>
    )
}
