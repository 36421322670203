import { CommonParams, CommonResponse } from "../model/common";
import { UserInfo, UserResponse } from "../model/user-info";
import API from "../utils/axios";
let common = "/v1/api";

export const GET_USER = (data: CommonParams): Promise<UserResponse> => {
  return API.get(`${common}/users`, { params: data });
};

export const UPDATE_USER = (
  uuid: string,
  data: UserInfo
): Promise<UserResponse> => {
  return API.put(`${common}/users/${uuid}`, data);
};

export const DELETE_USER_ORDER = (uuid: string): Promise<CommonResponse> => {
  return API.delete(`${common}/users/${uuid}`);
};
