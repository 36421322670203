import React from "react";
import { AllThingDMV } from "./all-things-dmv";
import { SelectService } from "./select-service";
import { VehicleServices } from "./vehicle-services";
import { NoMoreWaitingSection } from "./no-more-waiting";

export const HomePageComponents = () => {

  return (
    <div className="col-12 px-0 homepage-content-overflow">
      <AllThingDMV />
      <SelectService />
      <VehicleServices />
      <NoMoreWaitingSection />
    </div>
  );
};
