import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Loader } from '../../components/loader';
import { GET_USER_ORDER } from '../../app/services/user-orders.service';
import moment from 'moment';
import { OrdersData, OrdersResponse } from '../../app/model/orders';
import toast from 'react-hot-toast';

export const OrderStatus = () => {
    const params = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [userOrders, setUserOrders] = useState<OrdersData[]>();


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        // if (params?.user_cart_uuid) {
            getUserVehicleInfo();
        // }
    }, [])

    const getUserVehicleInfo = () => {
        setLoading(true);
        GET_USER_ORDER({ user_cart_uuid: params?.user_cart_uuid, user_uuid: params?.user_uuid ? params?.user_uuid : '' }).then((res: OrdersResponse) => {
            if (res?.status === "fail") {
                toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.error?.data ? res?.error?.data : res?.message, { position: "top-right" })
                setLoading(false);
            } else {
                if (res) {
                    setUserOrders([...res?.data?.data]);
                    setLoading(false);
                }
            }
        });
    }
    return (
        <div className="primary-bg mt-xl-2">
            <div className='container'>
                <div className='row mx-0'>
                    <Loader LoadingReducer={loading} />
                    <div className='col-md-12 py-4 primary-bg mx-md-auto'>
                        <div className='row py-4 p-md-3 mt-xl-0 pt-xl-0 mx-lg-3'>
                            <div className='col-11 col-md-12 bg-white py-3 p-md-3 px-xl-5 mx-auto rounded-4 border-0 text-center'>
                                <div className='row my-3'>
                                    <div className='col-md-12 d-flex justify-content-center font-bebas fs-32 ls-1'>ORDER STATUS</div>
                                </div>
                                {userOrders?.map((data: OrdersData, index: number) => {
                                    return <div key={index} className='border border-dark rounded my-3 p-2 py-3'>
                                        <div className='row mb-0'>
                                            <div className='col-6 text-start text-md-end font-Oswald fw-bold fs-25 ls-1'>Order Number</div>
                                            <div className='col-6 text-start text-md-start font-Oswald fw-500 fs-25 ls-1'>{data?.order_id}</div>
                                        </div>
                                        <div className='row mb-0'>
                                            <div className='col-6 text-start text-md-end font-Oswald fw-bold fs-25 ls-1'>Order Date</div>
                                            <div className='col-6 text-start text-md-start font-Oswald fw-500 fs-25 ls-1'>{moment(data?.created_at).format('MM/DD/YYYY')}</div>
                                        </div>
                                        <div className='row mb-0'>
                                            <div className='col-6 text-start text-md-end font-Oswald fw-bold fs-25 ls-1'>Vehicle VIN </div>
                                            <div className='col-6 text-start text-md-start font-Oswald fw-500 fs-25 ls-1'>{data?.user_vehicle?.vehicle_vin_number}</div>
                                        </div>
                                        <div className='row mb-0'>
                                            <div className='col-6 text-start text-md-end font-Oswald fw-bold fs-25 ls-1'>Order Status</div>
                                            <div className='col-6 text-start text-md-start fw-normal'>
                                                <div className='font-Oswald fw-500 fs-25 ls-1'>{data?.order_status === 0 ? 'PROCESSING' : 'COMPLETED'}</div>
                                            </div>
                                            <div className='col-md-6 text-start text-md-end font-Oswald fw-bold'></div>
                                            <div className='text-start col-12 col-md-6 my-3 my-md-1 fs-18 fw-400 lh-sm'>Your order has been processed and all fees due paid to the California Department of Motor Vehicles.</div>
                                        </div>
                                        <div className='row mb-0'>
                                            <div className='col-6 text-start text-md-end font-Oswald fw-bold fs-25 ls-1'>Shipping Status</div>
                                            <div className='col-6 text-start text-md-start fw-normal'>
                                                <div className='font-Oswald fw-500 fs-25 ls-1'>{data?.shipping_status}</div>
                                            </div>
                                            <div className='col-md-6 text-start text-md-end font-Oswald fw-bold'></div>
                                            <div className='text-start col-12 col-md-6 my-3 my-md-1 fs-18 fw-400 lh-sm'>Your order has been prepared for shipping and is awaiting pickup by a carrier.</div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
