import React, { useEffect } from 'react'
import LICENSE from "../../assets/images/SimpleTags DMV License_page-0001.jpg"

export const License = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='primary-bg'>
      <div className='container'>
        <div className='row mx-0 '>
          <div className='col-md-12 py-4 primary-bg mx-md-auto'>
            <div className='row py-4 p-md-3 mx-3 mt-xl-5 pt-xl-4'>
              <div className='col-11 col-md-12 bg-white py-3 p-md-3 p-md-5 mx-auto rounded-4 border-0 text-center'>
                <div className='px-md-4 py-3'>
                  <h2 className='primary-color mb-5'>Our License</h2>
                  <div className='text-start'>
                    <p className='fw-500'>
                      Simple Tags has been officially licensed by the California Department of Motor Vehicles (OL #43119) to provide DMV services on their behalf.  Simple Tags is a private company and not owned or operated by any government agency or the CA Department of Motor Vehicles.
                    </p>
                    <div className='row'>
                      <div className='col-12'></div>
                      <img src={LICENSE} alt="LICENSE" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div>
  )
}
