import { CommonResponse } from "../model/common";
import { OrdersParams, OrdersResponse } from "../model/orders";
import API from "../utils/axios";
let common = "/v1/api";

export const GET_USER_ORDER = (data: OrdersParams): Promise<OrdersResponse> => {
  return API.get(`${common}/user/orders`, { params: data });
};

export const DELETE_USER_ORDER = (uuid: string): Promise<CommonResponse> => {
  return API.delete(`${common}/user/orders/${uuid}`);
};
