import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { MenuData } from '../../app/model/menu';

export const SideMenu = () => {
    const user_uuid = sessionStorage.getItem('user_id');
    const navItems: MenuData[] = [
        {
            name: "My Orders",
            route: `/dashboard/my-orders/${user_uuid}`
        },
        {
            name: "My Garage",
            route: `/dashboard/my-garage/${user_uuid}`
        },
        {
            name: "Our Services",
            route: "/dashboard/our-services"
        },
        {
            name: "My Profile",
            route: `/dashboard/profile/${user_uuid}`
        },
    ]
    return (
        <div className="side-menu mt-5 d-flex justify-content-end w-100">
            <ul style={{ width: '100px' }}>
                {navItems.map((item: MenuData, index: number) => (
                    <li className="list-style-none py-1" key={index}>
                        <NavLink to={item.route} className={"text-decoration-none text-dark fs-m-15"}>{item.name}</NavLink>
                    </li>
                ))}
            </ul>
        </div>
    )
}
