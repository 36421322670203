import { CommonParams } from "../model/common";
import { VehicleResponse } from "../model/vehicle-details";
import API from "../utils/axios";
let common = "/v1/api";

export const USER_VEHICLE_INFO = (
  data: CommonParams
): Promise<VehicleResponse> => {
  return API.get(`${common}/user/vehicles`, { params: data });
};
