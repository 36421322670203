import React, { useEffect, useState } from 'react';

interface Props {
    optionsList?: any;
    customPlaceholder?: any;
    onSelectionChange?: (selectedOptions: string[]) => void
}

export  const CustomMultiSelect = (props: Props) => {
    const [selectedOptions, setSelectedOptions] = useState<any>([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const options = props?.optionsList;

    useEffect(() => {
        if (props.onSelectionChange) {
            props.onSelectionChange(selectedOptions);
        }
    }, [selectedOptions, props.onSelectionChange]);

    const handleOptionClick = (option: any) => {
        setSelectedOptions((prevSelectedOptions: any) => {
            if (prevSelectedOptions.includes(option)) {
                return prevSelectedOptions.filter((item: any) => item !== option);
            } else {
                return [...prevSelectedOptions, option];
            }
        });
    };

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    return (
        <div className="col-12 dropdown">
            <button className="dropdown-btn w-100" onClick={toggleDropdown}>
                <div className='d-flex justify-content-between px-2'>
                    <div>{props?.customPlaceholder}</div>
                    <div>{dropdownVisible ? <><i className="bi bi-chevron-down"></i></> : <><i className="bi bi-chevron-up"></i></>}</div>
                </div>
            </button>
            {dropdownVisible && (
                <div className="dropdown-content w-100">
                    {options.map((option: any, index: number) => (
                        <label
                            key={index}
                            className={`dropdown-item ${selectedOptions.includes(option.value) ? 'checked' : 'unchecked'}`}
                            onClick={() => handleOptionClick(option.value)}
                        >
                            {option.label}
                            {selectedOptions.includes(option.value) && <i className="bi bi-check icon-font"></i>}
                        </label>
                    ))}
                </div>
            )}
        </div>
    );
};