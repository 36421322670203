import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { DVMDetails } from '../../app/model/dvm-details';
import { useParams } from 'react-router-dom';

interface Props {
    multipleOrders?: any;
    UserDataReducer: DVMDetails[];
    vehicleData?: DVMDetails[];
    isUserCart?: boolean;
}

const DataTable = (props: Props) => {
    const params = useParams()
    const [loading, setLoading] = useState<boolean>(false);
    const [tableData, settableData] = useState<DVMDetails[]>([]);
    const [totalPrice, setTotalPrice] = useState<string>();

    useEffect(() => {
        if (props?.isUserCart) {
            const data: DVMDetails[] = [];
            if (props?.vehicleData) {
                const sum = props?.vehicleData.reduce((accumulator: any, current: any) => {
                    return accumulator + parseFloat(current.final_price);
                }, 0);
                const formattedSum = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(sum);
                setTotalPrice(formattedSum);
                props?.vehicleData.forEach((element: any) => {
                    element.user_vehicle.final_price = element?.final_price;
                    data.push(element?.user_vehicle)
                });
                settableData([...data]);
            }
        } else {
            settableData(props?.vehicleData || []);
        }
    }, [props?.vehicleData])

    const formatCurrency = (value: any) =>
        new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(value);

    return (
        <div className='row'>
            <div className='col-md-12 table-responsive'>
                <table className="table  table-bordered border-dark">
                    <thead>
                        <tr>
                            <th scope="col">Vehicle Type</th>
                            {!params?.cart_uuid && !params?.address_uuid && <th scope="col">Vehicle Make</th>}
                            <th scope="col">License Plate</th>
                            <th scope="col text-wrap">VIN Number</th>
                            {(params?.cart_uuid || params?.address_uuid) && <th scope="col">Subtotal</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData?.map((data: DVMDetails, index: number) => {
                            return <tr key={index}>
                                <td>{data?.vehicle_type}</td>
                                {!params?.cart_uuid && !params?.address_uuid && <td>{data?.vehicle_make}</td>}
                                <td>{data?.vehicle_license_plate}</td>
                                <td className='text-wrap'>{data?.vehicle_vin_number}</td>
                                {(params?.cart_uuid || params?.address_uuid) && <td>{formatCurrency(data?.final_price)}</td>}
                            </tr>
                        })}
                    </tbody>
                    {(params?.cart_uuid || params?.address_uuid) && <tfoot className='border-1'>
                        <tr>
                            <td colSpan={4} className="text-end">
                                <strong> Total: {totalPrice}</strong>
                            </td>
                        </tr>
                    </tfoot>}
                </table>
            </div>
        </div >
    )
}

const mapStateToProps = (state: any) => {
    return {
        UserDataReducer: state.UserDataReducer,
    };
};

const connectedNavBar = connect(mapStateToProps)(DataTable);
export { connectedNavBar as DataTable };