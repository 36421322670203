import React from "react";
import Logo from './../../assets/images/logo.svg';
import SimpleTagLogo from './../../assets/images/logo.svg';
import { NavLink } from "react-router-dom";

export const Footer = () => {
  return (
    <div className="primary-bg">
      <div className="container px-5 pt-lg-0 pt-3">
        <div className="row pb-3 pt-md-0">
          <div className="col-12">
            <div className="row footer_content">
              <div className="col-3 col-md-2 my-4 my-sm-0 my-sm-0 px-md-4">
                <img src={SimpleTagLogo} className="footer-logo-image" />
              </div>
              <div className="col-9 col-md-7 fw-bold my-3 my-auto text-start ps-4">
                <NavLink to="/privacy-policy" className="text-decoration-none text-dark me-1">Privacy Policy</NavLink> |
                <NavLink to="/terms-conditions" className="text-decoration-none text-dark me-1"> Terms & Condition</NavLink>
                | {" "}
                {/*   <span className="me-1">Customer Support</span>  | <span className="me-1">Services & Fees</span> | {" "} */}
                <NavLink to="/license" className="text-decoration-none text-dark me-1"> License</NavLink>
              </div>
              <div className="col-12 col-md-3 px-3 px-sm-3 lh-sm fs-15 fw-500 font-bebas-nue-pro-regular-Italic pt-3 ps-2 footer_content_text">
                Simple Tags is a Delegated Provider by the CA Department of Motor
                Vehicles (OL #43119) to provide registration and title services.
                Simple Tags is a private company and not owned or operated by any
                government agency or the CA Department of Motor Vehicles.
              </div>
            </div>
            <div className="text-white fs-11">
              @2023 SimpleTags.com, All Rights Reserved
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
