import React from 'react'
import { DataTable } from '../data-table'
import { VehicleBillSummary } from './vehicle-bill-summary'

export const VehicleInfoBillSummary = () => {
  return (
    <div>
      <DataTable />
      <div className='border border-dark mb-3'>
        <div className='text-end fw-bold me-5 fs-5 py-2'><span className='fs-5'>Total:</span><span className='fs-5'> $ 5,000.00</span></div>
      </div>
      <div className='border border-dark my-3'>
        <div className='text-center fw-md-bold py-2 fs-4 font-Oswald'>7ABC123 - 1 Year Renewal</div>
      </div>
      < VehicleBillSummary/>
    </div>
  )
}
