import axios from "axios";
import appConfig from "./.././../config/constant";

const API = axios.create({
  baseURL: appConfig.BASE_URL,
});
API.interceptors.request.use(function (config: any) {
  const jwt_token: string = sessionStorage.getItem("jwt_token") || '';
  config.headers["Content-Type"] = "application/json";
  config.headers.Authorization = jwt_token ? `AMPERE ${jwt_token}` : ""; //AMPERE   BLAZON                                                                                                                                                                                               
  return config;
});

API.interceptors.response.use(
  (response) => {
    if (response.data.data) {
      return response.data;
    } else {
      return response.data ? response.data : {};
    }
  },
  (error: any) => {
    if (
      error?.response?.data.error ===
      "Missing authorization token!" ||
      error?.response?.data?.error === "Session Token Is Missing!"
    ) {
      sessionStorage.clear();
      const userToken: string = sessionStorage.getItem("userToken") || '';
      window.location.href = "/";
      if (!userToken) {
        window.location.href = "/";
      }
    }
    return error.response && error.response.data ? error.response.data : {};
  }
);
export default API;
