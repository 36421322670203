import { GetParamsData } from "../model/common";
import { ServiceTypeResponse } from "../model/service-types";
import API from "../utils/axios";
let common = "/v1/api";

export const GET_SERVICE_TYPE = (
  data: GetParamsData
): Promise<ServiceTypeResponse> => {
  return API.get(`${common}/service/types`, { params: data });
};
