import { CommonParams } from "../model/common";
import {
  ShippingAddressForm,
  ShippingAddressResponse,
  ShippingAddressResponseData,
} from "../model/shipping-address";
import API from "../utils/axios";
let common = "/v1/api";

export const GET_USER_SHIPPING_ADDRESS = (
  data: CommonParams
): Promise<ShippingAddressResponseData> => {
  return API.get(`${common}/user/shipping/address`, { params: data });
};

export const CREATE_USER_SHIPPING_ADDRESS = (
  data: ShippingAddressForm
): Promise<ShippingAddressResponse> => {
  return API.post(`${common}/user/shipping/address`, data);
};

export const UPDATE_USER_SHIPPING_ADDRESS = (
  address_uuid: string,
  data: ShippingAddressForm
): Promise<ShippingAddressResponse> => {
  return API.put(
    `${common}/user/shipping/address/${address_uuid}`,
    data
  );
};
