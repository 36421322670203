import React, { useEffect } from 'react'
import { AllThingDMV } from '../../components/home-page-component/all-things-dmv'
import { VehicleRegistrationForm } from '../../components/registration-page/vehicle-registration-form'
import { VehicleServices } from '../../components/home-page-component/vehicle-services'
import { NoMoreWaitingSection } from '../../components/home-page-component/no-more-waiting'

export const VehicleRegistration = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='container-fluid'>
      <div className="row">
        <AllThingDMV />
        <VehicleRegistrationForm />
        <VehicleServices />
        <NoMoreWaitingSection />
      </div>
    </div>
  )
}
