import React, { useEffect } from 'react'

export const Congratulations = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <div className='col-md-12'>
      <div className='row mx-0'>
        <div className='col-md-12 py-4 primary-bg mx-md-auto'>
          <div className='row py-4 p-md-3'>
            <div className='col-11 col-md-10 bg-white py-3 p-md-3 mx-auto rounded-4 border-0 text-center'>
              <div className='row px-md-4'>
                <div className='col-md-10 py-5 mx-auto px-md-4'>
                  <div className=''>
                    <h3 className='font-Oswald'>Congratulations! Your DMV vehicle registration renewal has been processed.</h3>
                  </div>
                  <div className="my-3 fs-5">Your Order ID Number Is: 8237898237</div>
                  <div>Please check your inbox at customer@gmail.com for your order confirmation and FREE digital registration e-Card attachment. Your e-Card is proof
                    of valid registration right now! For questions or concerns reach us at 1-800-000-000 or email support at orders@simpletags.com
                  </div>
                  <div className='my-3 fw-bold'><a className='fw-bold cursor-pointer'>Download Your DMV registration e-Card Here</a></div>
                  <div className='fw-bold fs-6'><span className='fw-bold fs-5'>BONUS: </span>Get replacement DMV registration cards for one year, free!</div>
                  <div className='my-3 fw-bold'>Lose it or misplace it, we'll replace it at no charge. It's our way of saying thanks.</div>
                  <div className='primary-color fw-bold'>Leave a review  and help others avoid DMV lines too!</div>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
