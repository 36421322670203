import React, { useEffect, useState } from "react";
import eventBus from "../../app/utils/TriggerContext";
import { ServiceTypeData } from "../../app/model/service-types";
export const AllThingDMV = () => {
    const [serviceType, setServiceType] = useState('ALL THINGS DMV HASSLE-FREE');

    useEffect(() => {
        const handleEvent = (msg: ServiceTypeData) => {
            setServiceType(msg?.name);
        };

        eventBus.on('triggerEvent', handleEvent);

        // Cleanup the event listener when the component unmounts
        return () => {
            eventBus.off('triggerEvent', handleEvent);
        };
    }, []);
    return (
        <div className="container-fluid mt-5 mt-md-3 mb-md-4 pb-1 text-center">
            <h5 className="primary-color fs-45 font-bebas-nue-pro-regular fw-600 ls-3 px-4 px-md-0 text-uppercase" >{serviceType}</h5>
            <div className="h5 mb-0 fs-32 font-proxima-nova-regular fw-400 ls-05 px-4 px-md-0">Online access to all of your DMV needs.</div>
            <div className="h5 fs-32 font-proxima-nova-regular fw-400 ls-05 px-4 px-md-0">Save your time and avoid the lines and unnecessary stress</div>
        </div>
    )
}