import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import DMV from './../../assets/images/DMV_Logo.png';
import Car from './../../assets/images/SimpleTags_icons_car.png';
import Bike from './../../assets/images/SimpleTags_icons_motorcycle.png';
import Ship from './../../assets/images/Simple Tags_icons_boat.png';
import Trolly from './../../assets/images/SimpleTags_icons_trailer.png';
import Truck from './../../assets/images/SimpleTags_icons_commercial truck.png';
import Loading from './../../assets/images/spinner-blue.gif';
import { CommonStaticData } from '../../app/model/common';
import { GET_SYSTEM_PARAMS } from '../../app/services/system-params.service';

export const ServiceDmvRecordsCheck = () => {
  const navigation = useNavigate();
  const params = useParams();
  const [DMVDetails, setDMVDetails] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setTimeout(() => {
      navigation(`/shipping-address/${params?.user_uuid}/${params?.vehicle_uuid}`);
    }, 1000);
    getSystemParams();
  }, [])
  const vehicleImages: CommonStaticData[] = [
    {
      title: "Car",
      image: Car
    },
    {
      title: "Bike",
      image: Bike
    },
    {
      title: "Ship",
      image: Ship
    },
    {
      title: "Trolly",
      image: Trolly
    },
    {
      title: "Truck",
      image: Truck
    },
  ]

  const getSystemParams = () => {
    GET_SYSTEM_PARAMS({ code: 'DMV_LOGO' }).then((res: any) => {
      if (res?.status === "fail") {
        setLoading(false);
      } else {
        if (res) {
          if (res?.data?.data?.length > 0) {
            setDMVDetails(res?.data?.data[0]);
          }
          setLoading(false);
        }
      }
    });
  }
  const onDmvNavigation = () => {
    navigation("/shipping-address")
  }
  return (
    <div className='col-md-12 primary-bg px-5 py-3'>
      <h5 className='fw-bold text-center font-proxima-novas'>Select a Service for Vehicle Registration or Title</h5>
      <div className='row mx-auto '>
        <div className='col-12 d-block d-sm-none d-flex justify-content-start me-auto'>
          <div className='d-flex justify-content-between'>
            {vehicleImages?.map((vehicle: CommonStaticData, index: number) => (
              <div className='px-0' key={index}>
                <img src={vehicle?.image} className='vehicle-image' />
              </div>
            ))}
          </div>
        </div>
        <div className='d-none d-sm-block col-12 col-sm-10 border py-3 my-3 my-sm-0 mb-lg-4 px-lg-3 bg-white rounded-4 mx-auto
         d-sm-flex flex-column justify-content-center align-items-center' onClick={onDmvNavigation}>
          <div className='text-center'>
            <img src={Loading} className='spinner' />
          </div>
          <h5 className='text-center fw-bold'>Checking DMV Records</h5>
          <div className='text-center'>Please wait...</div>
        </div>
        <div className='d-none d-sm-block col-sm-1 d-none d-sm-block d-flex justify-content-start me-auto'>
          <div className='d-flex flex-column justify-content-between my-5 pt-4'>
            {vehicleImages?.map((vehicle: CommonStaticData, index: number) => (
              <div className='px-0' key={index}>
                <img src={vehicle?.image} className='vehicle-image' />
              </div>
            ))}
          </div>
        </div>
        <div className='d-none d-sm-block row ms-lg-5 ps-lg-5 d-block d-sm-none'>
          <div className='col-12 d-sm-flex justify-content-center mx-auto'>
            <div className='mx-auto d-flex justify-content-center'>
              <img src={DMV} alt="dmv" className='dmv-image' />
            </div>
            <div className='align-items-center mt-2'>
              <h4 className='ms-ms-3 secondary-color text-center font-Oswald'>DMV Delegated Provider</h4>
              <div className='ms-md-4 secondary-color font-Oswald'>Officially Entrusted by the CA Department of Motor Vehicles</div>
            </div>
          </div>
        </div>
        <div className='d-block d-sm-none bg-white  rounded-4' onClick={onDmvNavigation}>
          <div className='col-12 col-sm-10  border py-3 my-3 my-sm-0 mb-lg-4 px-lg-3 bg-white rounded-4 mx-auto d-flex flex-column justify-content-center align-items-center '>
            <div className='text-center'>
              <img src={Loading} className='spinner' />
            </div>
            <h5 className='text-center fw-bold'>Checking DMV Records</h5>
            <div className='text-center'>Please wait...</div>
          </div>
          <div className='col-sm-1 d-none d-sm-block d-flex justify-content-start me-auto'>
            <div className='d-flex flex-column justify-content-between my-5 pt-4'>
              {vehicleImages?.map((vehicle: CommonStaticData, index: number) => (
                <div className='px-0' key={index}>
                  <img src={vehicle?.image} className='vehicle-image' />
                </div>
              ))}
            </div>
          </div>
          <div className='row ms-lg-5 ps-lg-5 d-block d-sm-none'>
            <div className='col-12 d-sm-flex justify-content-center mx-auto'>
              <div className='mx-auto d-flex justify-content-center'>
                <img src={DMVDetails?.value?.dmv_logo || DMV} alt="dmv" className='dmv-image' />
              </div>
              <div className='align-items-center mt-2'>
                <h4 className='ms-md-3 secondary-color text-center font-Oswald'>{DMVDetails?.value?.title ? DMVDetails?.value?.title : 'DMV Delegated Provider'}</h4>
                <div className='ms-0 ms-md-4 secondary-color text-center text-md-start font-Oswald'>{DMVDetails?.value?.sub_title ? DMVDetails?.value?.sub_title : 'Officially Entrusted by the CA Department of Motor Vehicles'}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
