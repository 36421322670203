import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import {
  UserData,
} from "./user-data/reducer";

export const store = createStore(
  combineReducers({
    UserDataReducer: UserData,
  }),
  applyMiddleware(thunk as any)
);
