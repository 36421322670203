import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { ShippingAddressVehicleDetails } from '../../components/common-components/shipping-address/shipping-address-vehicle-details';
import { CREATE_USER_SHIPPING_ADDRESS, GET_USER_SHIPPING_ADDRESS, UPDATE_USER_SHIPPING_ADDRESS } from '../../app/services/user-address-shipping.service';
import { USER_VEHICLE_INFO } from '../../app/services/vehicle-details.service';
import { Loader } from '../../components/loader';
import { DynamicObject } from '../../app/model/common';
import { ShippingAddressForm, ShippingAddressResponse } from '../../app/model/shipping-address';
import { VehicleResponse } from '../../app/model/vehicle-details';
import { DVMDetails } from '../../app/model/dvm-details';
import { GET_USER, UPDATE_USER } from '../../app/services/user.service';
import { UserResponse } from '../../app/model/user-info';
import Sates from '../../app/utils/state.json'
import { GET_SYSTEM_PARAMS } from '../../app/services/system-params.service';
import toast from 'react-hot-toast';
import { formatPhoneNumber, getOnlyNumbers } from '../../app/utils/common-functions';

export const ShippingAddress = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [isShippingAddressExist, setIsShippingAddressExist] = useState<boolean>(false);
    const [isOwner, setIsOwner] = useState(true);
    const [userDetails, setUserDetails] = useState<any>([]);
    const [overNightPrice, setOverNightPrice] = useState<any>();


    const [shippingAddressData, setShippingAddressData] = useState<ShippingAddressForm>({
        first_name: "",
        last_name: "",
        city: "",
        state: "CA",
        zip_code: "",
        email: "",
        phone_no: "",
        is_owner: isOwner,
        company_name: "",
        street_address: "",
        unit: "",
        is_overnight_shipping: true,
    })
    const [formErrors, setFormErrors] = useState<DynamicObject>({});
    const navigation = useNavigate();
    const params = useParams();
    const [userVehicleInfo, setUserVehicleInfo] = useState<DVMDetails[]>([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        getUserVehicleInfo();
        getSystemParams();
    }, [])

    const getUserVehicleInfo = () => {
        setLoading(true);
        USER_VEHICLE_INFO({ uuid: params?.vehicle_uuid, user_uuid: params?.user_uuid ? params?.user_uuid : '' }).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.error?.data ? res?.error?.data : res?.message, { position: "top-right" })
                setLoading(false);
            } else {
                if (res) {
                    setUserVehicleInfo([...res?.data?.data]);
                    // if (res?.data?.userShippingAddress.length > 0) {
                    //     setIsShippingAddressExist(true);
                    //     setShippingAddressData({ ...res?.data?.userShippingAddress[0] });
                    // }
                    setLoading(false);
                }
            }
        });
        gettingUserAddress();

    }

    const gettingUserAddress = () => {
        GET_USER_SHIPPING_ADDRESS({ user_uuid: params?.user_uuid }).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.error?.data ? res?.error?.data : res?.message, { position: "top-right" })
                setLoading(false);
            } else {
                if (res) {
                    if (res?.data?.data.length > 0) {
                        setIsShippingAddressExist(true);
                        res.data.data[0].phone_no = formatPhoneNumber(res.data.data[0].phone_no);
                        setShippingAddressData({ ...res?.data?.data[0] });
                    } else {
                        getUserInfo(params?.user_uuid);
                    }
                    setLoading(false);
                }
            }
        });
    }

    const getUserInfo = (userUuid: any) => {
        setLoading(true);
        GET_USER({ uuid: params?.user_uuid }).then((res: UserResponse) => {
            if (res?.status === "fail") {
                toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.error?.data ? res?.error?.data : res?.message, { position: "top-right" })
                setLoading(false);
            } else {
                if (res) {
                    const response = res?.data?.data[0];
                    if (!shippingAddressData?.email) {
                        setShippingAddressData({ ...shippingAddressData, email: response?.email });
                    }
                    setUserDetails({ ...res?.data?.data[0] })
                    setLoading(false);
                }
            }
        });
    }

    const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        let formattedValue = value;
        setShippingAddressData((prevData: ShippingAddressForm) => ({
            ...prevData,
            [name]: formattedValue,
        }));
        setFormErrors((prevErrors: DynamicObject) => ({
            ...prevErrors,
            [name]: validateSpecificField(name, formattedValue),
        }));
    }

    const handleBlur = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;

        // Apply formatting if the field is the mobile number
        let formattedValue = value;
        if (name === "phone_no") {
            // Remove any non-numeric characters
            const numericValue = value.replace(/\D/g, '');

            // Format as (xxx) xxx-xxxx if length is 10
            if (numericValue.length >= 10) {
                formattedValue = numericValue.replace(
                    /^(\d{3})(\d{3})(\d{4})$/,
                    '($1) $2-$3'
                );
            } else {
                formattedValue = numericValue; // Keep the unformatted value if length is less than 10
            }
        }

        setShippingAddressData((prevData: ShippingAddressForm) => ({
            ...prevData,
            [name]: formattedValue,
        }));
        setFormErrors((prevErrors: DynamicObject) => ({
            ...prevErrors,
            [name]: validateSpecificField(name, formattedValue),
        }));
    };

    const handleChangeOvernight = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShippingAddressData({ ...shippingAddressData, is_overnight_shipping: e.target.checked })
    }

    const handleChangeCompetitively = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newIsOwner = e.target.value === 'yes';
        setIsOwner(newIsOwner);
        setShippingAddressData({ ...shippingAddressData, is_owner: newIsOwner })
    };

    const handleChangeNewAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isNewAddress = e.target.checked;
        if (isNewAddress) {
            setIsShippingAddressExist(false);
            setShippingAddressData({
                first_name: "",
                last_name: "",
                city: "",
                state: "CA",
                zip_code: "",
                email: "",
                phone_no: "",
                is_owner: isOwner,
                company_name: "",
                street_address: "",
                unit: "",
                is_overnight_shipping: true,
            });
        } else {
            gettingUserAddress();
        }
        // shippingAddressData, setShippingAddressData
    };


    const validateSpecificField = (name: string, value: string) => {
        const emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-z]{3,16}.[a-z]{2,6}$/;
        const mobileRegEx = /^(\+1|1)?\s*\(?\d{3}\)?\s*-?\s*\d{3}\s*-?\s*\d{4}$/; ///^\(\d{3}\) \d{3}-\d{4}$/;
        if (name === "email") {
            if (!value) {
                return "Please enter email"
            } else if (!emailRegEx.test(value)) {
                return "Please enter a valid email address";
            }
        }
        if (name === "phone_no") {
            if (!value) {
                return "Please enter mobile phone"
            } else if (!mobileRegEx.test(value)) {
                return "Please enter a valid mobile phone";
            }
        }
        return "";
    }

    const validateForm = () => {
        const errors: DynamicObject = {};
        if (!shippingAddressData?.first_name) {
            errors.first_name = "Please enter first name"
        }

        if (!shippingAddressData?.last_name) {
            errors.last_name = "Please enter last name"
        }

        if (!shippingAddressData?.street_address) {
            errors.street_address = "Please enter street address"
        }

        // if (!shippingAddressData?.unit) {
        //     errors.unit = "Please enter apt / unit / suite"
        // }

        if (!shippingAddressData?.city) {
            errors.city = "Please enter city"
        }

        if (!shippingAddressData?.state) {
            errors.state = "Please enter state"
        }
        if (!shippingAddressData?.zip_code) {
            errors.zip_code = "Please enter zip code"
        }
        if (userDetails?.email === "" || !shippingAddressData?.email) {
            errors.email = "Please enter email"
        }
        if (!shippingAddressData?.phone_no) {
            errors.phone_no = "Please enter mobile phone"
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const onContinue = () => {
        const isValid = validateForm();
        if (isValid) {
            setLoading(true);
            const address = shippingAddressData
            const uuid = shippingAddressData?.uuid
            address.user_uuid = params?.user_uuid;
            address.user_vehicle_uuid = params?.vehicle_uuid;

            if (!userDetails?.mobile_number) {
                UPDATE_USER(userDetails?.uuid || params?.user_uuid, { mobile_number: address?.phone_no ? getOnlyNumbers(address?.phone_no) : '' }).then((res: UserResponse) => {
                    if (res?.status === "fail") {
                        toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.error?.data ? res?.error?.data : res?.message, { position: "top-right" })
                        setLoading(false);
                    } else {
                        if (res) {

                        }
                    }
                });
            }
            if (isShippingAddressExist) {
                address.phone_no = address?.phone_no ? getOnlyNumbers(address?.phone_no) : '';
                delete address['created_dt'];
                delete address['updated_at'];
                delete address['uuid'];
                UPDATE_USER_SHIPPING_ADDRESS(uuid ? uuid : '', address).then((res: ShippingAddressResponse) => {
                    if (res?.status === "fail") {
                        toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.message, { position: 'top-right' })
                        setLoading(false);
                    } else {
                        if (res) {
                            navigation(`/address-confirmation/${params?.user_uuid}/${params?.vehicle_uuid}/${res?.data?.uuid}`);
                            setLoading(false);
                        }
                    }
                });
            } else {
                shippingAddressData.phone_no = shippingAddressData?.phone_no ? getOnlyNumbers(shippingAddressData?.phone_no) : '';
                CREATE_USER_SHIPPING_ADDRESS(shippingAddressData).then((res: ShippingAddressResponse) => {
                    if (res?.status === "fail") {
                        toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.message, { position: 'top-right' })
                        setLoading(false);
                    } else {
                        if (res) {
                            setLoading(false);
                            navigation(`/address-confirmation/${params?.user_uuid}/${params?.vehicle_uuid}/${res?.data?.uuid}`);
                        }
                    }
                });
            }
        } else {
            console.log("Please enter valid details");
        }
    }



    const getSystemParams = () => {


        GET_SYSTEM_PARAMS({ code: 'OVERNIGHT_SHIPPING' }).then((res: UserResponse) => {
            if (res?.status === "fail") {
                toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.message, { position: 'top-right' })
                setLoading(false);
            } else {
                if (res) {
                    if (res?.data?.data?.length > 0) {
                        setOverNightPrice(res?.data?.data[0]);
                    }
                    setLoading(false);
                }
            }
        });
    }

    return (
        <div className='primary-bg mt-xl-2'>
            <div className='container'>
                <Loader LoadingReducer={loading} />
                <div className='col-md-12 primary-bg mx-auto'>
                    <div className='row py-4 py-md-4 p-md-3'>
                        <div className='col-10 col-md-11 bg-white p-1 p-md-3 mx-auto rounded-4 custom_border_2'>
                            <div className='row px-4 py-4 py-md-4 px-md-5'>
                                <div className='col-lg-6 pe-xl-5'>
                                    <h4 className='fw-bold text-center text-md-start font-Oswald ls-1 fs-32 mb-3'>What is your shipping address?</h4>
                                    <div className='fw-400 lh-sm'>Please enter your shipping address and select delivery method. This is required to calculate your fees.</div>
                                    <div className='row my-3'>
                                        <div className='col-md-12 my-1 pe-xl-0 mb-2 mb-lg-3'>
                                            <label className='form-label fw-bold'>Add new address</label>
                                            <div>
                                                <label className="switch">
                                                    <input type="checkbox" onChange={handleChangeNewAddress} />
                                                    <span className="slider"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-md-6 my-1 pe-xl-0 mb-2 mb-lg-3'>
                                            <label className='form-label fw-bold'>Enter First Name * </label>
                                            <input type="text" name="first_name" autoComplete="given-name" className='form-control custom-input' value={shippingAddressData?.first_name} onChange={(e) => handleInput(e)} />
                                            {formErrors.first_name && (
                                                <div className="text-danger text-start w-100">{formErrors.first_name} </div>
                                            )}
                                        </div>
                                        <div className='col-md-6 my-1 mb-2 mb-lg-3'>
                                            <label className='form-label fw-bold'>Enter Last Name * </label>
                                            <input type="text" name="last_name" autoComplete="family-name" className='form-control custom-input' value={shippingAddressData?.last_name} onChange={(e) => handleInput(e)} />
                                            {formErrors.last_name && (
                                                <div className="text-danger text-start w-100">{formErrors.last_name} </div>
                                            )}
                                        </div>
                                        <div className='col-md-12 my-1 mb-2 mb-lg-3'>
                                            <div className='form-label fw-bold'>Are you the registered vehicle's owner? * </div>
                                            <div className="">
                                                <input type="radio" id="test3" className='me-3 cursor-pointer' name="is_owner" checked={isOwner === true}
                                                    value="yes" onChange={handleChangeCompetitively} />
                                                <label htmlFor="test3">Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                                {/* </div>
                                            <div className="mt-2"> */}
                                                <input type="radio" id="test" className='ms-3 me-2 cursor-pointer' name="is_owner" checked={isOwner === false}
                                                    value="no" onChange={handleChangeCompetitively} />
                                                <label htmlFor="test">No</label>
                                            </div>
                                        </div>
                                        <div className='col-md-12 my-1 mb-2 mb-lg-3'>
                                            <label className='form-label fw-bold'>Company Name (optional)</label>
                                            <input type="text" name="company_name" autoComplete="organization" className='form-control custom-input' value={shippingAddressData?.company_name} onChange={(e) => handleInput(e)} />
                                            {formErrors.company_name && (
                                                <div className="text-danger text-start w-100">{formErrors.company_name} </div>
                                            )}
                                        </div>
                                        <div className='col-md-6 my-1 pe-xl-0 mb-2 mb-lg-3'>
                                            <label className='form-label fw-bold'>Street Address * </label>
                                            <input type="text" name="street_address" autoComplete="address-line1" className='form-control custom-input' value={shippingAddressData?.street_address} onChange={(e) => handleInput(e)} />
                                            {formErrors.street_address && (
                                                <div className="text-danger text-start w-100">{formErrors.street_address} </div>
                                            )}
                                        </div>
                                        <div className='col-md-6 my-1 mb-2 mb-lg-3'>
                                            <label className='form-label fw-bold'>Apt / Unit / Suite</label>
                                            <input type="text" name="unit" autoComplete="address-line2" className='form-control custom-input' value={shippingAddressData?.unit} onChange={(e) => handleInput(e)} />
                                            {formErrors.unit && (
                                                <div className="text-danger text-start w-100">{formErrors.unit} </div>
                                            )}
                                        </div>
                                        <div className='col-md-12 my-1 mb-2 mb-lg-3'>
                                            <label className='form-label fw-bold'>City *</label>
                                            <input type="text" name="city" autoComplete="address-level2" className='form-control custom-input' value={shippingAddressData?.city} onChange={(e) => handleInput(e)} />
                                            {formErrors.city && (
                                                <div className="text-danger text-start w-100">{formErrors.city} </div>
                                            )}
                                        </div>
                                        <div className='col-md-6 my-1 pe-xl-0 mb-2 mb-lg-3'>
                                            <label className='form-label fw-bold'>State * </label>
                                            <select name="state" className='form-control custom-input' value={shippingAddressData?.state} onChange={(e) => handleInput(e)} >
                                                <option value="" disabled>Select Sate</option>
                                                {Sates?.map((data: any) => { return <option key={data?.id} value={data?.id}>{data?.text}</option> })}
                                            </select>
                                            {/* <input type="text" name="state" className='form-control custom-input' value={shippingAddressData?.state} onChange={(e) => handleInput(e)} /> */}
                                            {formErrors.state && (
                                                <div className="text-danger text-start w-100">{formErrors.state} </div>
                                            )}
                                        </div>
                                        <div className='col-md-6 my-1 mb-2 mb-lg-3'>
                                            <label className='form-label fw-bold'>ZIP Code *</label>
                                            <input type="text" name="zip_code" autoComplete="postal-code" maxLength={6} className='form-control custom-input' value={shippingAddressData?.zip_code} onChange={(e) => handleInput(e)} />
                                            {formErrors.zip_code && (
                                                <div className="text-danger text-start w-100">{formErrors.zip_code} </div>
                                            )}
                                        </div>
                                        <div className='col-md-6 my-1 pe-xl-0 mb-2 mb-lg-3'>
                                            <label className='form-label fw-bold'>Email *</label>
                                            <input type="text" name="email" autoComplete="email" className='form-control custom-input' value={shippingAddressData?.email} onChange={(e) => handleInput(e)} />
                                            {formErrors.email && (
                                                <div className="text-danger text-start w-100">{formErrors.email} </div>
                                            )}
                                        </div>
                                        <div className='col-md-6 my-1 mb-2 mb-lg-3'>
                                            <label className='form-label fw-bold'>Mobile Phone *</label>
                                            <input type="text" name="phone_no" autoComplete="tel" className='form-control custom-input' value={shippingAddressData?.phone_no} onChange={(e) => handleInput(e)} onBlur={(e) => handleBlur(e)} />
                                            {formErrors.phone_no && (
                                                <div className="text-danger text-start w-100">{formErrors.phone_no} </div>
                                            )}
                                        </div>
                                        <div className='col-md-12 my-1'>
                                            <div className="form-check bg_gray p-0 py-1 border-2" style={{ border: '1px solid #c2c1c' }} >
                                                <input className="form-check-input fw-bold cursor-pointer ms-2" name="is_overnight_shipping" type="checkbox" defaultChecked onChange={handleChangeOvernight} id="flexRadioDefault3" />
                                                <label className="form-check-label fw-500 ms-2 my-1" htmlFor="flexRadioDefault3"><b>Add overnight shipping for ${overNightPrice?.value?.overnight_shipping_price}</b></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 ps-xl-3'>
                                    <ShippingAddressVehicleDetails vehicleData={userVehicleInfo} onSubmit={() => onContinue()} />
                                </div>
                                <div className='col-12 d-none d-lg-block text-center pt-5'>
                                    <button className='border-0 primary-bg primary-button' onClick={onContinue}>CONTINUE</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
