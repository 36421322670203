import { CartPayload, CartResponseData } from "../model/cart";
import { CommonParams, CommonResponse } from "../model/common";
import API from "../utils/axios";
let common = "/v1/api";

export const GET_USER_CART = (data: CommonParams): Promise<CartResponseData> => {
  return API.get(`${common}/user/carts`, { params: data });
};

export const USER_CART = (data: CartPayload): Promise<CommonResponse> => {
  return API.post(`${common}/user/carts`, data);
};
