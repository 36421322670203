import React from 'react'
import { NavLink } from 'react-router-dom'

export const ThankYou = () => {
    return (
        <div className='container-fluid login-container d-flex flex-column justify-content-center align-items-center'>
            <div className="row">
                <div className="col-md-12">
                    <div className='text-center'>
                        <h4 className='primary-color font-Oswald fs-3'>Thank You!</h4>
                        <div className='font-proxima-nova my-3 fw-normal'>You will receive an email message shortly with a link that will take you straight in to your SimpleTags account</div>
                        <div className='my-3'>
                            <NavLink to="/" className="text-decoration-none"><button className='secondary-button border-0 rounded px-3'>BACK</button></NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
