import React from 'react'

export const VehicleBillSummary = () => {
    return (
        <div className='border border-dark my-3 fw-bold my-3'>
            <div className='row my-2'>
                <div className='col-6 text-end '>DMV Registration Fees</div>
                <div className='col-6 text-start'>$1,500.00</div>
            </div>
            <div className='row my-2'>
                <div className='col-6 text-end '>CA DMV BPA Fee</div>
                <div className='col-6 text-start'>$1,500.00</div>
            </div>
            <div className='row my-2'>
                <div className='col-6 text-end '>SimpleTags Service Fee</div>
                <div className='col-6 text-start'>$1,000.00</div>
            </div>
            <div className='row my-2'>
                <div className='col-6 text-end '>Shipping & Handling</div>
                <div className='col-6 text-start'>$500.00</div>
            </div>
            <div className='row my-2'>
                <div className='col-6 text-end '>Convenience Fee</div>
                <div className='col-6 text-start'>$500.00</div>
            </div>
            <div className='row my-2'>
                <div className='col-6 text-end '>Ship To</div>
                <div className='col-6 text-start fw-normal'>
                    <div className='text-start fw-normal'>John Doe</div>
                    <div className='text-start fw-normal'>123 Main Street</div>
                    <div className='text-start fw-normal'>Springfield, IL 62704</div>
                </div>
            </div>
            <div className='row mx-2 my-3'>
                <div className='col-6 text-start text-md-end'>
                    <button className='px-3 fs-5 rounded border-0 primary-bg fw-bold'>EDIT</button>
                </div>
                <div className='col-6 text-end text-md-start'>
                    <button className='px-3 fs-5 rounded border-0 primary-bg fw-bold'>DELETE</button>
                </div>
            </div>
        </div>
    )
}
