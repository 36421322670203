import React from 'react'
import { MyOrders } from '../pages/orders-dashboard/my-orders/my-orders'
import { Route, Routes } from 'react-router-dom'
import { MyGarage } from '../pages/orders-dashboard/my-garage/my-garage'
import { OurServices } from '../pages/orders-dashboard/our-services/our-services'
import { MyProfile } from '../pages/orders-dashboard/my-profile/my-profile'

export const DashboardRoutes = () => {
    return (
        <Routes>
            <Route path="/:user_uuid" element={<MyOrders />} />
            <Route path="/my-orders/:user_uuid" element={<MyOrders />} />
            <Route path="/my-garage/:user_uuid" element={<MyGarage />} />
            <Route path="/our-services" element={<OurServices />} />
            <Route path="/profile/:user_uuid" element={<MyProfile />} />
        </Routes>
    )
}
